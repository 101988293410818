<template>
	<ConfirmDialog></ConfirmDialog>
	
	<div class="p-grid" style="height: 100%">
		<div class="p-col-12 p-md-12" style="height: 100%">
			<div class="card p-fluid" style="height: 100%">
                <CrmDataTable baseEntityName="salesorder" :options="CrmDataTable_options" :isHeaderVisible="true" @onRowSelect="onRowSelect" />
            </div>
        </div>
    </div>
</template>

<script>
import user from '../store/user';

export default {
	data() {
		return {
			CrmDataTable_options: {
				searchAttributes: ["ordernumber", "customeridname", "bm_frmno", "bm_urunidname", "quoteidname", "bm_po", "bm_uniteidname", "bm_frmno"],
				/*
				filterParent: [
					{ 'name': 'bm_sehir', 'filterOperator': 'eq', 'value': '34' },
					{ 'name': 'telephone1', 'filterOperator': 'like', 'value': '(212)%' },
					{ 'name': 'createdon', 'filterOperator': 'on-or-after', 'value': '2022-01-01' },
				],
				*/
				customFormat: [
					{ 'logicalName': 'salesorder', 'attributeName' : 'bm_geneltoplam', 'format' : 'n0'},
				],
				/*
				views: [
					{ 'viewName': 'Etkin Siparişler', 'savedQueryId': 'E6F001DF-303C-447B-B184-0F65CD00C3F9' },
					{ 'viewName': 'Tüm Siparişler', 'savedQueryId': '4B2438F6-4949-484B-A272-6F694A2B2FAE' },
					{ 'viewName': 'Bu Ay Siparişler', 'savedQueryId': 'EBB35B76-9718-E811-B78C-E41F136A9174' },
					{ 'viewName': 'Bu Yıl Siparişler', 'savedQueryId': '42501F85-F591-E211-9AD9-E61F135C9177' },
					{ 'viewName': 'Geçen Yıl Siparişler', 'savedQueryId': '8BFBCC97-0DD3-E611-8A4F-E61F135C9177' },
				],
				*/
            },
		}
	},
	created() {
		if (this.profileData) {
			try {
				if (this.profileData.moduller) {
					const yetkisiVarmi = user.checkPermissionModul(this.profileData, 'Siparişler');
					if (yetkisiVarmi == false) {
						console.log('this.profileData içinde yetki bulunamadı!');
						console.log(this.profileData);
						this.$router.replace({ name: 'accessdenied' });
					}
				}
			} catch (error) {
				console.log('yetkisiVarmi HATA: ' + error);
			}
		}
		else {
			console.log(this.profileData);
			console.log('this.profileData BOŞ GELDİ');
		}
	},
	methods: {
		onRowSelect(data) {
			let features = 'directories=no,menubar=no,status=no,titlebar=no,toolbar=no';

			let routeData = this.$router.resolve({ name: 'salesorder', params: { id: data.entityId } });
			window.open(routeData.href, '_blank', features);
		}
	},
	computed: {
		profileData(){
			return this.$store.getters.getProfile;
		}
	}
}
</script>

<style lang="scss" scoped>

</style>
